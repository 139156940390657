<template>
  <!-- Dropdown button -->
  <div
    :id="'dropDownButton-' + props.uniqueIdString"
    :data-dropdown-toggle="'dropdown-' + props.uniqueIdString"
    data-dropdown-offset-distance="10"
    data-dropdown-placement="bottom"
    :class="`relative inline-flex items-center text-sm focus:ring-2 font-medium rounded-lg px-5 py-2.5 text-center w-full ${
      props.variant == 'primary'
        ? 'text-primary hover:text-white border border-primary hover:bg-primary mr-2 mb-2'
        : 'text-primary-600 border border-gray-300 bg-gray-50 '
    }`"
    type="button"
  >
    <!-- Render Selection Text if they have picked something, else default label provided by prop -->
    <div class="inline-flex items-center w-full justify-between">
      <div
        v-if="actionSelected.length !== 0"
        class="flex gap-2 flex-wrap overflow-auto max-h-8"
      >
        <div
          v-for="(action, index) in actionSelected"
          :key="index"
          class="bg-secondary/20 w-fit flex gap-2 items-center py-1 px-2 text-sm rounded-lg font-bold"
        >
          {{ action[props.textKey] }}
          <CloseIcon2
            @click="handleRemoveUser(action)"
            class="cursor-pointer"
          />
        </div>
      </div>
      <span v-else>
        {{ props.buttonText }}
      </span>

      <!-- Dropdown icon -->
      <svg
        class="w-2.5 min-w-2.5 h-2.5 ms-3"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 6"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="m1 1 4 4 4-4"
        />
      </svg>
    </div>
  </div>

  <!-- Dropdown Menu -->
  <div
    :id="'dropdown-' + props.uniqueIdString"
    class="left-0 z-10 hidden absolute bg-white divide-y divide-gray-100 rounded-lg shadow max-w-20rem w-auto"
  >
    <div class="p-3">
      <div class="relative">
        <div
          class="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none"
        >
          <img :src="SearchIcon" class="w-4 h-4 text-gray-500" />
        </div>
        <input
          :id="'input-' + props.buttonText + '-search'"
          v-model="searchVal"
          type="text"
          class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:border-primary"
          :placeholder="'Search ' + props.buttonText"
        />
      </div>
    </div>
    <!-- Searchable options list -->
    <ul
      :id="'dropwdownList-' + props.uniqueIdString"
      class="searchable-options-list"
    >
      <li
        v-for="item in findMatches(searchVal)"
        :key="item[props.textKey]"
        class="options-li"
      >
        <a
          :id="'option-' + item[props.textKey] + props.uniqueIdString"
          class="option"
          :class="{
            'selected-option': actionSelected.some(
              action => action.id == item.id
            ),
          }"
          @click="selectActionByClick(item)"
        >
          {{ item[props.textKey] }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { initDropdowns } from 'flowbite';
import { ref, defineProps, defineEmits, onMounted, watch } from 'vue';
import SearchIcon from '@/assets/search-icon-fb.svg';
import CloseIcon2 from '@/assets/CloseIcon2.vue';

const props = defineProps({
  /** REQUIRED: option list, should be list of dict [{'id': 1, 'text': 'Display this!'}] */
  options: {
    default: () => [],
    type: Array,
  },
  /** REQUIRED if more than 1 on same page: used to id the dropdown, should be unique across page */
  uniqueIdString: {
    default: '',
    type: String,
  },
  /** REQUIRED: key attr that holds text to display in list as option */
  textKey: {
    default: '',
    type: String,
  },
  /** Optional: Dropdown buttons text to display */
  buttonText: {
    default: 'Select',
    type: String,
  },
  /** Optional: More button styling */
  buttonClass: {
    default: '',
    type: String,
  },
  variant: {
    default: 'primary',
    type: String,
  },
});
const dropdownButton = ref('');
const dropdownMenu = ref('');
const dropdownList = ref('');
const dropdownItems = ref('');
let currentIndex = -1;

const actionSelected = ref([]);
const searchVal = ref('');

onMounted(() => {
  initDropdowns();
  dropdownButton.value = document.getElementById(
    'dropDownButton-' + props.uniqueIdString
  );
  dropdownMenu.value = document.getElementById(
    'dropdown-' + props.uniqueIdString
  );
  dropdownList.value = document.getElementById(
    'dropwdownList-' + props.uniqueIdString
  );
  dropdownItems.value = dropdownList.value.getElementsByTagName('a');
});

const selectActionByClick = item => {
  if (!actionSelected.value.some(action => action.id == item.id)) {
    actionSelected.value.push(item);
  } else {
    handleRemoveUser(item);
  }
  emit('updateSelection', actionSelected);
};

/** Implements search functionality for dropdown */
function findMatches(v) {
  if (!v || v === '') return props.options;
  const matches = props.options.filter(option =>
    option[props.textKey]
      ?.toLowerCase()
      .trim()
      .includes(v?.toLowerCase().trim() || null)
  );
  return matches;
}

const handleRemoveUser = user => {
  actionSelected.value = actionSelected.value.filter(
    item => item.id !== user.id
  );
};

const emit = defineEmits(['updateSelection']);

const clearSelection = () => {
  actionSelected.value = [];
  emit('updateSelection', '');
};

defineExpose({ clearSelection });
</script>

<!-- scoping these styles overrides css coming from tabs component in ActivityStream.vue & ActivityTab.vue -->
<style scoped>
.options-list,
.searchable-options-list {
  @apply flex-col bg-white py-2 text-sm text-gray-700 cursor-pointer;
}

.searchable-options-list {
  @apply overflow-y-auto max-h-60 whitespace-nowrap;
}

.options-li {
  @apply shadow-none text-black;
}

.option {
  @apply block px-4 py-2 hover:bg-primary-100 bg-white;
}

.selected-option {
  @apply bg-secondary hover:bg-secondary-hover;
}
</style>
