<template>
  <div :id="`${header.key}-table-cell`" class="flex">
    <span
      :class="`truncate max-w-[${props.width}] text-${props.textColor} ${props.spanClasses}`"
      >{{ item[header.key] }}</span
    >
  </div>
</template>

<script setup>
import { watch } from 'vue';
import { ref } from 'vue';

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  header: {
    type: Object,
    default: () => {},
  },
  width: {
    type: String,
    default: '15em',
  },
  textColor: {
    type: String,
    default: 'black',
  },
  spanClasses: {
    type: String,
    default: '',
  },
});

const input = ref(String(props.item[props.header.key]));

watch(
  () => props.item,
  () => {
    input.value = String(props.item[props.header.key]);
  }
);
</script>
