<template>
  <li>
    <div
      class="flex items-center p-2 text-base font-normal rounded-lg hover:bg-sky-400 hover:bg-secondary group transition-all duration-300"
      :class="{
        'justify-center': collapsed,
        'pl-3.5': !collapsed,
        'w-[3.5rem]': collapsed,
        'w-full': !collapsed,
      }"
      @click="togglePinned"
    >
      <component :is="icon" class="w-7 h-7" />
      <span
        v-show="!collapsed && !collapsedAfterAnimations"
        class="ml-3"
        :class="{
          'pl-[2rem]': nestedLevel == 1,
          'pl-[3rem]': nestedLevel == 2,
          'pl-[4rem]': nestedLevel == 3,
        }"
      >
        {{ name }}
      </span>
      <svg
        v-show="!collapsed && !collapsedAfterAnimations"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="3"
        class="w-5 h-5 mx-2 mt-1 stroke-white"
        :class="!dropdownPinned ? null : '-rotate-180'"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
    </div>
    <ul v-show="dropdownPinned && !collapsed && !collapsedAfterAnimations">
      <template v-for="navItem in dropdownItems" :key="navItem.name">
        <SidebarDropdown-Flowbite
          v-if="
            navItem.dropdownItems && rbac_store.hasAccessToRoute(navItem.name)
          "
          :name="navItem.sidebarName"
          :dropdownItems="navItem.dropdownItems"
          :collapsed="collapsed"
          :collapsed-after-animations="collapsedAfterAnimations"
          :icon="navItem.flowbiteIcon"
          :nested-level="nestedLevel + 1"
        >
        </SidebarDropdown-Flowbite>
        <SidebarItemFlowbite
          v-else-if="rbac_store.hasAccessToRoute(navItem.name)"
          :path="navItem.path"
          :name="navItem.sidebarName"
          :collapsed="collapsed"
          :collapsed-after-animations="collapsedAfterAnimations"
          :icon="navItem.flowbiteIcon"
          :nested-level="nestedLevel + 1"
        />
      </template>
    </ul>
  </li>
</template>
<script setup>
import { ref } from 'vue';
import { useRbacStore } from '@/stores/useRbac';
import SidebarItemFlowbite from './SidebarItem-Flowbite.vue';
import { useSessionStorage } from '@vueuse/core';

const rbac_store = useRbacStore();

const props = defineProps({
  collapsed: {
    default: false,
    type: Boolean,
  },
  collapsedAfterAnimations: {
    default: false,
    type: Boolean,
  },
  dropdownItems: {
    default: null,
    type: Object,
  },
  name: {
    default: '',
    type: String,
  },
  nestedLevel: {
    default: 0,
    type: Number,
  },
  icon: {
    default: null,
    type: Object,
  },
});

const dropdownPinned = useSessionStorage(
  'pinned-dropdown-' + props.name,
  false
);

function togglePinned() {
  dropdownPinned.value = !dropdownPinned.value;
}
</script>
