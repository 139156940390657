<template>
  <div
    class="relative flex flex-col"
    :class="{
      [props.containerClasses]: true,
      [props.sizeClassses]: true,
      [props.colorClasses]: true,
    }"
  >
    <div
      v-if="showWarning"
      class="absolute top-1 right-1 flex flex-col items-center justify-center bg-warning-yellow rounded-xl text-white w-[1.25rem] h-[1.25rem]"
      @mouseenter="openPopup"
      @mouseleave="hidePopup"
    >
      <div
        v-if="showPopup"
        class="absolute top-[-3rem] z-10 text-sm whitespace-nowrap flex flex-col items-center justify-center bg-white border-2 border-solid border-warning-yellow rounded-xl text-black w-[9rem] h-[2.5rem]"
      >
        Cannot filter by date
      </div>
      !
    </div>
    <slot />
  </div>
</template>
<script setup>
import { ref } from 'vue';

const showPopup = ref(false);

function openPopup() {
  showPopup.value = true;
}

function hidePopup() {
  showPopup.value = false;
}
const props = defineProps({
  containerClasses: {
    type: String,
    default: 'justify-center items-center rounded-md',
  },
  sizeClassses: {
    type: String,
    default: '',
  },
  colorClasses: {
    type: String,
    default: 'bg-white',
  },
  showWarning: {
    type: Boolean,
    default: false,
  },
});
</script>
