import { defineStore } from 'pinia';
import * as helpers from '@/utils/helpers';
import {
  FAKE_WORK_GAP_DATA,
  fetchAndSortData,
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from '@/utils/ogFakeData';

export const useWorkGapStore = defineStore('workgap', {
  state: () => ({
    searchLimit: 15,
    searchPage: 1,
    searchDirection: 'asc',
    searchField: 'user',
    totalRows: 0,
    fakeWorkGapData: [],
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
    selectedUser: null,
    selectedActivityGap: null,
    OGFakeWorkGapData: FAKE_WORK_GAP_DATA,
    filtersApplied: [],
    currentMonth: 'October',
    monthlyTotal: { value: 27, colorTernary: 0 },
    previousYearAverage: { value: 27, colorTernary: 0 },
    monthlyVS6MonthAverageCount: {
      value: 27,
      colorTernary: 2,
      caretTernary: 2,
    },
    monthlyVS6MonthAveragePercent: {
      value: '22.7%',
      colorTernary: 1,
      caretTernary: 1,
    },
    monthlyInsights: [
      {
        key: 'highestMonth',
        value: 27,
        textValue: 'August 2024',
        colorTernary: 1,
      },
      {
        key: 'lowestMonth',
        value: 27,
        textValue: 'October 2024',
        colorTernary: 2,
      },
      {
        key: 'currentMonth',
        value: 27,
        textValue: 'October 2024',
        colorTernary: 0,
      },
    ],
    highOccurrenceDays: [
      { value: 27, textValue: 'Thursday', colorTernary: 0 },
      { value: 27, textValue: 'Monday', colorTernary: 0 },
      { value: 27, textValue: 'Wednesday', colorTernary: 0 },
    ],
    mostOccurenceUsers: [
      { user: 'Diane', smallhours: '3', largehours: '4', total: '14' },
      { user: 'Casey', smallhours: '6', largehours: '2', total: '10' },
      { user: 'Robert', smallhours: '6', largehours: '2', total: '18' },
      { user: 'Dan', smallhours: '6', largehours: '2', total: '23' },
      { user: 'Sam', smallhours: '6', largehours: '2', total: '16' },
    ],
    isLoading: false,
  }),
  getters: {
    getFiltersApplied: state => {
      return state.filtersApplied;
    },
    sortData: state => {
      return (order, field) => {
        return helpers.sort(order, field, state.fakeWorkGapData);
      };
    },
  },
  actions: {
    async thisWillHaveABetterName(page = 1, direction = 'asc', field = 'user') {
      //so on pagination results will persist
      this.searchPage = page;
      this.searchDirection = direction;
      this.searchField = field;

      const reqBody = {
        limit: this.searchLimit,
        page,
        direction,
        field,
      };

      try {
        const response = await fetchAndSortData(reqBody);
        this.totalRows = response.totalRows;
        this.fakeWorkGapData = response.fakeData;
      } catch (err) {
        console.error('Some fake error', err);
      }
    },
    resetFilters() {
      this.selectedUser = null;
      this.selectedActivityGap = null;
      this.startDate = DEFAULT_START_DATE;
      this.endDate = DEFAULT_END_DATE;
      this.filtersApplied = [];
    },
    applyFilters() {
      //There will be more here but this will do for now
      this.filtersApplied = [{ name: 'Date Range Applied' }];
    },
  },
});
