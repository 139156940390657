<template>
  <span
    class="flex flex-row gap-1 items-center"
    :class="{
      [props.fontClasses]: true,
      [props.sizeClassses]: true,
      ['text-secondary fill-secondary']: colorTernary == 0,
      ['text-bad-red fill-bad-red']: colorTernary == 1,
      ['text-stat-green fill-stat-green']: colorTernary == 2,
    }"
  >
    <CaretIcon v-if="caretTernary == 1"></CaretIcon>
    <CaretIcon v-if="caretTernary == 2" :flip="true"></CaretIcon>
    {{ value + (percentage ? '%' : '') }}
  </span>
</template>
<script setup>
import CaretIcon from '@/assets/misc-icons/CaretIcon.vue';

const props = defineProps({
  value: {
    type: String,
    default: '',
  },
  percentage: {
    type: Boolean,
    default: false,
  },
  caretTernary: {
    type: Number,
    default: 0,
  },
  fontClasses: {
    type: String,
    default: 'font-extrabold text-xl',
  },
  sizeClassses: {
    type: String,
    default: '',
  },
  colorTernary: {
    type: Number,
    default: 0,
  },
  showWarning: {
    type: Boolean,
    default: false,
  },
});
</script>
