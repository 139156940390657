function getFakeMin() {
  return `${Math.round(Math.random() * 2000)} min`;
}

function getFakeDate() {
  const now = new Date();
  const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6));
  const randomDate = new Date(
    sixMonthsAgo.getTime() +
      Math.random() * (Date.now() - sixMonthsAgo.getTime())
  );
  return randomDate.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
}

//This will happen on the back end - just mimicking what will happen for now
export function fetchAndSortData(fakeBody) {
  const { page, limit, direction, field } = fakeBody;
  const start = (page - 1) * limit;

  const getValue = item => {
    const value = item[field];
    if (field === 'time_since_last_activity') {
      return Number(value.split('min')[0]);
    }
    if (field.includes('activity')) {
      const [datePart, timePart] = value.split(' at ');
      const [month, day, year] = datePart.split(' ');
      const [time, period] = timePart.split(' ');
      let [hours, minutes] = time.split(':');

      hours = parseInt(hours);
      if (period === 'PM' && hours !== 12) hours += 12;
      if (period === 'AM' && hours === 12) hours = 0;

      return new Date(`${month} ${day} ${year} ${hours}:${minutes}`).getTime();
    }
    return value;
  };

  const sortedData = FAKE_WORK_GAP_DATA.sort((a, b) => {
    const valueA = getValue(a);
    const valueB = getValue(b);
    const multiplier = direction === 'asc' ? 1 : -1;
    return typeof valueA === 'number'
      ? (valueA - valueB) * multiplier
      : valueA.localeCompare(valueB) * multiplier;
  });

  return {
    fakeData: sortedData.slice(start, start + limit),
    totalRows: FAKE_WORK_GAP_DATA.length,
  };
}

export const DEFAULT_START_DATE = '04/01/2024';
export const DEFAULT_END_DATE = '10/31/2024';

export const FAKE_WORK_GAP_DATA = [
  {
    user: 'Test User 1',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 2',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 3',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Auser whose name starts with a',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'B user',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Baaaab User',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Quser',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'abcdefghijklmnopqrstuvwxyz',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 9',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'a-user-here',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: '@#re',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: '9023 user',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: '†∞∆¥ some special characters',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'B∑ahsd',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 15',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 16',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 17',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 18',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 19',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 20',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 21',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 22',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 23',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 24',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 25',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 26',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 27',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 28',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 29',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 30',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 31',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 32',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 33',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 34',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 35',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 36',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 37',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 38',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 39',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 40',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 41',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 42',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 43',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 44',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 45',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 46',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 47',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 48',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 49',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 50',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 51',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 52',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 53',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 54',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 55',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 56',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 57',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 58',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 59',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 60',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 61',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 62',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 63',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 64',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 65',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 66',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 67',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 68',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 69',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 70',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 71',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 72',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 73',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 74',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 75',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 76',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 77',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 78',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 79',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 80',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 81',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 82',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 83',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 84',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 85',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 86',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 87',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 88',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 89',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 90',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 91',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 92',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 93',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 94',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 95',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 96',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 97',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 98',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 99',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 100',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 101',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 102',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 103',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 104',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 105',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 106',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 107',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 108',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
  {
    user: 'Test User 109',
    prev_activity: getFakeDate(),
    activity: getFakeDate(),
    time_since_last_activity: getFakeMin(),
  },
];
