import { defineStore } from 'pinia';
import * as helpers from '@/utils/helpers';
import {
  FAKE_WORK_GAP_DATA,
  fetchAndSortData,
  DEFAULT_START_DATE,
  DEFAULT_END_DATE,
} from '@/utils/ogFakeData';

export const useFakeDataStore = defineStore('fakeData', {
  state: () => ({
    searchLimit: 15,
    searchPage: 1,
    searchDirection: 'asc',
    searchField: 'user',
    totalRows: 0,
    fakeWorkGapData: [],
    startDate: DEFAULT_START_DATE,
    endDate: DEFAULT_END_DATE,
    selectedUser: null,
    selectedActivityGap: null,
    OGFakeWorkGapData: FAKE_WORK_GAP_DATA,
  }),
  getters: {
    sortData: state => {
      return (order, field) => {
        return helpers.sort(order, field, state.fakeWorkGapData);
      };
    },
  },
  actions: {
    async thisWillHaveABetterName(page = 1, direction = 'asc', field = 'user') {
      //so on pagination results will persist
      this.searchPage = page;
      this.searchDirection = direction;
      this.searchField = field;

      const reqBody = {
        limit: this.searchLimit,
        page,
        direction,
        field,
      };

      try {
        const response = await fetchAndSortData(reqBody);
        this.totalRows = response.totalRows;
        this.fakeWorkGapData = response.fakeData;
      } catch (err) {
        console.error('Some fake error', err);
      }
    },
    resetFilters() {
      this.selectedUser = null;
      this.selectedActivityGap = null;
      this.startDate = DEFAULT_START_DATE;
      this.endDate = DEFAULT_END_DATE;
    },
  },
});
