<template>
  <div class="flex flex-col gap-2 w-full">
    <label v-if="label" class="font-bold p-0.5 text-gray-900 text-sm">{{
      props.label
    }}</label>
    <div :id="`${props.uniqueIdString}-button`" class="rounded-lg">
      <input
        ref="dateInput"
        datepicker
        datepicker-autohide
        :id="props.uniqueIdString"
        @changeDate="handleChangeDate"
        :placeholder="props.placeholder"
        type="text"
        class="text-gray-900 font-bold bg-gray-200 text-sm rounded-lg block w-full p-2.5 border-2 focus:!border-secondary"
        :class="{
          'border-secondary': props.modelValue,
        }"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { Datepicker } from 'flowbite';
import { format, parseISO } from 'date-fns';

const props = defineProps({
  placeholder: {
    type: String,
    default: 'Select date',
  },
  minDate: {
    type: String,
    default: '',
  },
  maxDate: {
    type: String,
    default: '',
  },
  modelValue: {
    default: null,
    type: String,
  },
  //This should be unique across all datepickers
  uniqueIdString: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
});

const dateInput = ref(null);
let datePickerInstance = null;

const initializeDatePicker = () => {
  if (datePickerInstance) {
    datePickerInstance.destroy();
  }
  const options = {
    format: 'mm/dd/yyyy',
    orientation: 'bottom',
    buttons: true,
    minDate: props.disabled ? '' : props.minDate,
    maxDate: props.maxDate,
  };
  datePickerInstance = new Datepicker(dateInput.value, options);
  if (props.modelValue) {
    //if the date is in the form yyyy/mm/dd, new Date() makes it yesterday for some reason so we have to do this
    if (props.modelValue.split('-')[0].length == 4) {
      const parsedDate = parseISO(props.modelValue);
      datePickerInstance.setDate(parsedDate);
    } else {
      datePickerInstance.setDate(new Date(props.modelValue));
    }
  }
  //the buttons are a package deal in flowbite so we have to be sneaky and hide the today one :)
  const todayButton = document.querySelectorAll('.today-btn');
  todayButton.forEach(element => {
    element.style.display = 'none';
  });
  const clearButton = document.querySelectorAll('.clear-btn');
  clearButton.forEach(element => {
    element.style.width = '100%';
    element.style.margin = '0px';
  });
};

// Watch for changes in the date props and update the datepicker instance
watch(
  () => [props.minDate, props.maxDate, props.modelValue],
  () => {
    initializeDatePicker();
  }
);

onMounted(() => {
  initializeDatePicker();
});

function handleChangeDate(event) {
  if (event.detail.date) {
    emit('update', format(event.detail.date, 'MM/dd/yyy'));
  } else {
    //we need it to be null in the backend
    emit('update', null);
  }
}

const clearSelection = defaultDate => {
  datePickerInstance.setDate(defaultDate);
  emit('update', defaultDate);
};

defineExpose({ clearSelection });

const emit = defineEmits(['update']);
</script>
<style>
.datepicker-picker span.selected {
  @apply bg-primary;
}
</style>
