<template>
  <div class="flex flex-col gap-3">
    <div class="flex gap-3">
      <StatsDatePicker
        ref="startDatePicker"
        label="Start Date"
        uniqueIdString="workGapStartDate"
        placeholder="MM/DD/YYYY"
        :model-value="startDate"
        :maxDate="endDate"
        @update="
          {
            newDate => {
              startDate = newDate;
            };
          }
        "
      />
      <StatsDatePicker
        ref="endDatePicker"
        label="End Date"
        uniqueIdString="workGapEndDate"
        placeholder="MM/DD/YYYY"
        :model-value="endDate"
        :minDate="startDate"
        @update="
          {
            newDate => {
              endDate = newDate;
            };
          }
        "
      />
    </div>
    <div class="flex flex-col gap-1">
      <span class="font-bold p-0.5 text-gray-900 text-sm"
        >Additional Criteria</span
      >
      <div class="flex bg-gray-200 p-3 rounded-lg gap-3">
        <StatsMultiSelectFlowbite
          ref="userDropdownRef"
          unique-id-string="work-gap-user-dropdown"
          variant="secondary"
          text-key="username"
          :show-search="true"
          :options="userDropdownOptions"
          button-text="User"
          @update-selection="
            selection => {
              userSelection = selection;
            }
          "
        />
        <StatsMultiSelectFlowbite
          ref="activityGapDropdownRef"
          unique-id-string="work-gap-activity-gap-dropdown"
          variant="secondary"
          text-key="activityGap"
          :show-search="true"
          :options="activityGapDropdownOptions"
          button-text="Activity Gap"
          @update-selection="
            selection => {
              activityGapSelection = selection;
            }
          "
        />
      </div>
    </div>
  </div>
  <hr class="h-px my-10 bg-gray-200 border-0 dark:bg-gray-700" />
  <div class="flex flex-row w-1/4 pr-4 self-end gap-2">
    <OutlinedButtonFlowBite
      class="text-gray-900 font-bold"
      @click="resetFilters"
    >
      Reset
    </OutlinedButtonFlowBite>
    <SolidButtonFlowBite
      variant="tertiary"
      class="!font-bold"
      @click="applyFilters"
    >
      Apply
    </SolidButtonFlowBite>
  </div>
</template>

<script setup>
import { useWorkGapStore } from '@/stores/stats/useWorkGapStats';
import DropDownFlowBite from '../forms/DropDownFlowBite.vue';
import StatsDatePicker from './StatsDatePicker.vue';
import OutlinedButtonFlowBite from '../buttons/OutlinedButtonFlowBite.vue';
import SolidButtonFlowBite from '../buttons/SolidButtonFlowBite.vue';
import { ref } from 'vue';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '@/utils/ogFakeData';
import StatsMultiSelectFlowbite from './StatsMultiSelectFlowbite.vue';

const workGapStore = useWorkGapStore();

const userSelection = ref(null);
const activityGapSelection = ref(null);
const startDate = ref(workGapStore.startDate);
const endDate = ref(workGapStore.endDate);

const userDropdownRef = ref(null);
const activityGapDropdownRef = ref(null);
const startDatePicker = ref(null);
const endDatePicker = ref(null);

const userDropdownOptions = workGapStore.OGFakeWorkGapData.map(
  (entry, index) => {
    return { id: index, username: entry.user };
  }
);
const activityGapDropdownOptions = [
  { id: 1, activityGap: '1.5 - 3 hours' },
  { id: 2, activityGap: '3+ hours' },
];

const resetFilters = () => {
  userDropdownRef.value.clearSelection();
  activityGapDropdownRef.value.clearSelection();

  startDatePicker.value.clearSelection(DEFAULT_START_DATE);
  endDatePicker.value.clearSelection(DEFAULT_END_DATE);
  workGapStore.resetFilters();
};

const applyFilters = () => {
  workGapStore.selectedUser = userSelection.value;
  workGapStore.selectedActivityGap = activityGapSelection.value;
  workGapStore.startDate = startDate.value;
  workGapStore.endDate = endDate.value;
  workGapStore.applyFilters();
};
</script>
